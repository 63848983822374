import React from "react"
import LogoImg from "../images/new-gg-logo.jpg";
import { Helmet } from "react-helmet";
// import { Helmet } from "react-helmet";

export const SEOSEARCHRESULTS = ({ title, description, image, url, children,isNoIndex }) => {
  let robots =isNoIndex ? "noindex":"all"

  //if(site.siteMetadata?.env !== "production"){
    //robots = "none"
  //}
  let imageUrl ="https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i/file_7035ecdafe.jpg?updated_at=2024-07-08T11:28:34.283Z"
  url = process.env.GATSBY_SITE_URL+url

  // check trailing slash
  if (!url.endsWith("/")) url = url + "/"

  // remove page- from url
  if (url.includes("page-")) {
    url = url
      .split("/")
      .filter(path => !path.includes("page-"))
      .join("/")
  }

  //remove multiple slashes
  url = url.replace(/([^:]\/)\/+/g, "$1")

  //set lowercase url
  url = url.toLowerCase()
  
  return (
    <>
    <Helmet>
      <title>{title}</title>
      <meta name="robots" content={robots}></meta>
      <meta name="description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content={`website`} />
      <meta property="og:description" content={description} />

      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:secure_url" content={imageUrl} />
      <meta property="og:image:url" content={imageUrl} />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content={"600"} />
      <meta property="og:image:height" content={"400"} />
      <meta property="og:image:alt" content={"Property result Image"} />
      <meta property="og:site_name" content="haus & haus" />


      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="hausandhaus.com" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:url" content={url} />
      <link rel="canonical" href={url} />
      </Helmet>
      </>
  )
}