import React from 'react'
import logoBlack from "../../images/logo-white.svg"
import { SEOSEARCHRESULTS } from "../../components/seo-search-results"


const index = () => {
  return (
    <div>
      <section className={"loader-wrapper"}>
        <div id="loader-wrapper">
          <div id="loader" class="new-loader">
            <img
              className="logo-white loader-logo"
              src={logoBlack}
              alt="logo"
            />
          </div>
        </div>
      </section>
    </div>
  )
}

export const Head = () => {
  return(
      <SEOSEARCHRESULTS
        title=""
        description=""
        url=""
        isNoIndex
      />
  )
}

export default index